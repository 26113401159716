import React from "react"
import { Flex, Box } from "rebass"
import Figure from "../components/Figure"
import { H1, H2, H3, H4, P, Ul } from "../components/VerticalRhythm"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import RespGrid from "../components/RespGrid"
import breakpoints from "../theme/breakpoints"

import PictureSet from "../components/PictureSet"
import imgObj05 from "../imgObjs/imgObj05"

const ImprintPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <Figure caption="">
      <PictureSet imgObj={imgObj05} />{" "}
    </Figure>
    <Section>
      <H1>Impressum</H1>
      <RespGrid min={"240px"}>
        <Box>
          <H4>Verantwortlich</H4>
          <P>
            Vera Anders
            <br />
            TexteKonzepte
            <br />
            Bettrather Str. 36
            <br />
            41061 Mönchengladbach
            <br />
            vera.anders@schreib-leicht.de
            <br />
            <br />
            <a href="http://textekonzepte.de/" target="_blank">
              textekonzepte.de
            </a>
            <br />
            <a href="http://vera-anders-coaching.de" target="_blank">
              vera-anders-coaching.de
            </a>
            <br />
            <a href="https://schreib-leicht.de" target="_blank">
              schreib-leicht.de
            </a>
            <br />
            <br />
            Steuer-Nr. 121/5007/4538
            <br />
          </P>
        </Box>
        <Box>
          <H4>Danke</H4>
          <P>
            an Anette Aretz und Philippe Kossebau für Logo, Umschlag, Satz und
            Design des Buches.
          </P>
          <p>
            an die Fotografen von{" "}
            <a
              href="https://unsplash.com/"
              title="Unsplash"
              target="_blank"
              rel="noopener"
              rel="nofollow"
            >
              Unsplash
            </a>{" "}
            und{" "}
            <a
              href="https://pixabay.com"
              title="pixabay"
              target="_blank"
              rel="noopener"
              rel="nofollow"
            >
              Pixabay
            </a>
            .
          </p>
        </Box>
      </RespGrid>
    </Section>
  </Layout>
)

export default ImprintPage
